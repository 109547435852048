<template>
  <div class="apparea deal-filter">
    <div v-if="!showMenu" class="deals">
      <filter-option-panel
        :resetOption="resetOption"
        :open="openFilterPanel"
        @emitMessage="receiveMessage"
      />
      <div class="filter-cont dVacation-pack" >
        <div v-if="isLoading" class="d-flex loading">
          <img class="m-auto" src="/assets/img/loading1.gif" alt="Loading..." />
        </div>
        <div v-else>
          <app-deal-menu-picker
            :pickCodeCategory="pickCodeCategory"
            :pickCodeSubcategory="pickCodeSubcategory"
            @emitMessage='receiveMessage'
          />
          <div class="p-2 bg-white">
            <app-deal-batch
              v-for="(batch, index) in sortedDealsPerHotel"
              :key="index"
              :batch="batch"
              @emitMessage="receiveMessage"
            />
          </div>
        </div>
      </div>
    </div>
    <menu-deal-category v-else
      @emitMessage="receiveMessage"
    />
    <b-modal id="share-this-modal" v-model="openShareModal">
      <template #modal-header>
        <h4> {{ $t("share-this.share-modal-title") }} </h4>
      </template>
      <template #default>
        <ShareThis :shareContent="shareContent"/>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          {{$t('booking.close')}}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BModal } from 'bootstrap-vue';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'DealFilterApp',
  components: {
    FilterOptionPanel: () => import('@/components/bonauf/atoms/FilterOptionPanel'),
    AppDealMenuPicker: () => import('@/components/bonauf/atoms/AppDealMenuPicker'),
    MenuDealCategory: () => import('@/components/bonauf/filterDeal/MenuDealCategory'),
    AppDealBatch: () => import('@/components/bonauf/atoms/AppDealBatch'),
    BModal,
    ShareThis: () => import('@/components/atoms/ShareThis'),
  },
  mixins: [imageUrlMixin],
  data() {
    return {
      showMenu: false,
      resetOption: false,
      openFilterPanel: false,
      pickCodeCategory: '',
      pickCodeSubcategory: '',
      filterOption: {
        destination: null,
        category: null,
        month: null,
        fromPrice: null,
        toPrice: null,
      },
      openShareModal: false,
      shareContent: null,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      sortedDealsPerHotel: 'GET_SORTED_DEALS_PER_HOTEL',
    }),
  },
  async created() {
    await this.fetchAssetsData();
  },
  mounted() {
  },
  methods: {
    receiveMessage(pMsg) {
      const { kind, data } = pMsg;
      switch (kind) {
        case 'toggleMenu':
          this.showMenu = !this.showMenu;
          break;
        case 'pickCategory':
          this.resetOption = true;
          setTimeout(() => {
            this.pickCodeCategory = data.code;
            this.pickCodeSubcategory = '';
            this.filterOption = {
              destination: null,
              category: data.code,
              month: null,
              fromPrice: null,
              toPrice: null,
            };
            this.$store.commit('SET_FILTER_OPTION', this.filterOption);
            this.pickCodeSubcategory = '';
            this.resetOption = false;
          }, 100);
          break;
        case 'pickFilterCategory':
          this.pickCodeCategory = data.code;
          this.pickCodeSubcategory = '';
          this.filterOption.category = data.code;
          this.$store.commit('SET_FILTER_OPTION', this.filterOption);
          break;
        case 'pickSubcategory':
          this.resetOption = true;
          this.pickCodeCategory = data.codeCategory;
          this.pickCodeSubcategory = data.codeSubcategory;
          setTimeout(() => {
            this.openFilterPanel = false;
            this.$store.commit('SET_APP_DEAL_CATEGORY_OPTIONS', data);
          }, 10);
          break;
        case 'updatedFilterOption':
          this.pickCodeCategory = '';
          this.pickCodeSubcategory = '';
          this.openFilterPanel = true;
          this.resetOption = false;
          this.filterOption = data;
          this.$store.commit('SET_FILTER_OPTION', data);
          break;
        case 'showShareModal':
          this.shareContent = data;
          this.openShareModal = !!data;
          break;
        default:
          // console.log(`please handle the ${kind} action`);
      }
    },
    async fetchAssetsData() {
      this.$store.dispatch('FETCH_APP_DEAL_MENU');
      this.$store.dispatch('FETCH_APP_DEAL_SUBCATEGORY');
      this.$store.dispatch('FETCH_APP_DEAL_PACKAGES');
    },
  },
};
</script>

<style scoped>
.loading {
  margin-top: 200px;
}
</style>

<style>
@import "/assets/css/bonaufApp.css";
</style>
